<template>
  <div v-if="userInfo != null">
    <van-tabs v-model="activeKey" @change="callback" sticky>
      <van-tab v-for="(item,key) in tabList" :key="key" :name="item.key" :title="item.title">
        <orderList :data="{hdid:hdid,status:activeKey==10?undefined:activeKey}" :order_type="1" :config="{type:2}"/>
      </van-tab>
    </van-tabs>

    <global :hdid="hdid"/>
  </div>
</template>

<script>
  import orderList from './components/orderList'
  import global from './components/global'
  import {mapGetters} from "vuex";
  export default {
    name:'order',
    components: {orderList,global},
    data() {
      return {
        activeKey:10,
        tabList:[
          {key:10,title:'全部'},
          {key:1,title:'待支付'},
          {key:2,title:'已支付'},
          {key:3,title:'已完成'},
          {key:0,title:'已取消'}
        ],
        hdid:undefined
      }
    },
    computed:{
      ...mapGetters(['snapshotuser','userInfo']),
    },
    created(){
      this.hdid=parseInt(this.$route.params.hdid)
    },
    mounted() {
    },
    methods: {
      callback(name){
        this.activeKey=name
      }
    }
  }
</script>
<style lang="less" scoped>

</style>
