<template>
	<van-list v-model="loading" :finished="finished" finished-text="已经到底了" @load="onPageChange" class="orderList">
		<van-row class="item" v-for="item in dataList" :key="item.id">
			<van-col span="24" class="ordernumber">订单编号：{{item.ordernumber}}<span :class="'status status'+item.status">{{statusList[item.status]}}</span></van-col>
			<van-col span="24">
				<van-divider :style="{height:'10px',margin:'0px'}"/>
			</van-col>
			<van-col span="24" class="tips" v-if="item.money>0&&item.status==1 && config.type==3">您还需要支付{{item.money}}元，请尽快完成支付!!</van-col>
			<van-col span="24" class="ginfo">
				<van-row type="flex" :gutter="10">
					<van-col span="6"><img :src="item.goodInfo.titleimg" class="img"/></van-col>
					<van-col span="18">
						<p class="title">{{item.goodInfo.title}}</p>
						<p class="price"><span>¥<em>{{item.uprice}}</em>元</span></p>
						<p class="stock">数量：{{item.num}}</p>
					</van-col>
				</van-row>
			</van-col>
			<van-col span="24">
				<van-divider :style="{height:'20px',margin:'0px'}"/>
			</van-col>
			<van-col span="24">
				<van-row type="flex" justify="space-between" align="center">
					<van-col class="total_price">合计:{{item.money}}元</van-col>
					<van-col class="action">
						<van-button plain type="info" size="small" @click="orderDetail(item)" v-if="item.status==3">{{config.type==3?'奖品详情':'订单详情'}}</van-button>
						<template v-if="item.status==1">
							<van-button plain type="info" size="small" @click="orderDetail(item)" v-if="hdInfo.state==1">
								
								<template v-if="config.type==3">{{item.money>0?'立即支付'+item.money+'领取':'立即领取'}}</template>
								<template v-else>立即支付</template>
							</van-button>
							<van-button type="default" size="small" @click="cancel(item.id)">{{config.type==3?'放弃奖品':'取消订单'}}</van-button>
						</template>
						<van-button plain type="info" size="small" v-if="item.status==2" @click="hx(item)">立即核销</van-button>
					</van-col>
				</van-row>
			</van-col>
		</van-row>
	</van-list>
</template>
<script>
	import {mapGetters} from "vuex";

  let status1=['已取消','待支付','已支付','已完成']
  let status2=['已取消','待支付','已支付','已完成']
	let status3=['已取消','待领奖','待核销','已完成']
  import {GetorderList,OrderCancel} from "../services/services";
export default {
  props: {
    data:Object,
    order_type:Number,
    config:{
      type:Object,
      default:()=>({
	      type:1 //1、2普通商品订单，3抽奖奖品
      })
    }
  },
  components: {},
  data() {
    return {
      status1,
      status2,
      status3,
      dataList:[],
      pagination:{
        pageIndex:1,
        pageSize:10,
        pageCount:0,
        total:0
      },
      loading:false,
      finished:false
    }
  },
  
  computed:{
    ...mapGetters(['hdInfo']),
    statusList(){
      return this['status'+this.config.type]
    }
  },
  mounted() {
    this.findList()
  },
  methods: {
    findList(star){
      this.loading = true
      let params = {
        searchbean:{
          hdid:this.data.hdid,
          status:this.data.status,
          order_type:this.order_type
        },
        pageIndex:this.pagination.pageIndex,
        pageSize:this.pagination.pageSize
      }
      star=star==undefined?true:star
      GetorderList(params).then(result => {
        this.loading=false
        if(result.data.code==1){
          const {list,recordCount,pageCount} = result.data.data
          this.dataList = star?list:[...this.dataList,...list]
          this.pagination.total = recordCount
          this.pagination.pageCount=pageCount
          if(this.dataList.length>=recordCount){
            this.finished = true
          }
        }else{
          this.$toast(result.data.msg)
        }
      })
    },
    cancel(orderId){
      this.$dialog.confirm({
        title: '确认'+(this.config.type==3?'放弃奖品':'取消订单')+'吗？',
        //message: '',
        theme: 'round-button',
      }).then(() => {
        this.$toast.loading({
          message: '正在取消...',
          forbidClick: true,
        });
        OrderCancel({orderId:orderId}).then(result => {
          if(result.data.code==1){
            this.$toast('已取消成功')
            this.findList()
          }else{
            this.$toast(result.data.msg)
          }
        })
      }).catch(() => {

      });
    },
    orderDetail(record){
      if(parseInt(process.env.VUE_APP_PRODUCTION)){
        location.href = process.env.VUE_APP_PAY_URL+'/wx/order/detail/'+record.id
      }else{
        this.$router.push({path:'/wx/order/detail/'+record.id})
      }
    },
    hx(record){
      this.$router.push({path:'/wx/scan/hx/'+record.id})
    },
    onPageChange() {
      if (this.pagination.pageIndex >= this.pagination.pageCount) {
        return false
      }
      this.pagination.pageIndex += 1
      this.findList(false)
    },
  }
}
</script>
<style lang="less" scoped>
  @import "@/assets/css/order.less";
</style>
